<template>
  <v-app>
    <Header/>
    <!-- <v-app-bar app color="#FFFFFF" light elevation="0" height="75" class="nav">

      <router-link :to="{ path: '/' }">
        <div class="d-flex align-center">
          <v-img
            class="shrink mt-1 hidden-sm-and-down"
            contain
            max-height="75"
            :src="LogoDark"
            max-width="125"
            style="margin-left: 2rem"
          />
        </div>
      </router-link>

      <nav class="options_regular">
        <router-link
          style="text-decoration: none"
          :to="{ path: '/', hash: '#seguros' }"
        >
          <v-btn text>
            <span class="mr-2">Seguros</span>
          </v-btn>
        </router-link>

        <router-link style="text-decoration: none" :to="{ path: '/cotizador' }">
          <v-btn text>
            <span class="mr-2">Cotizador</span>
          </v-btn>
        </router-link>

        <router-link
          style="text-decoration: none"
          :to="{ path: '/', hash: '#nosotros' }"
        >
          <v-btn text>
            <span class="mr-2">Nosotros</span>
          </v-btn>
        </router-link>

        <router-link
          style="text-decoration: none"
          :to="{ path: '/', hash: '#contacto' }"
        >
          <v-btn text>
            <span class="mr-2">Contacto</span>
          </v-btn>
        </router-link>

        <router-link
          style="text-decoration: none"
          :to="{ path: '/', hash: '#mis-seguros' }"
        >
          <v-btn text>
            <span class="mr-2">Mis seguros</span>
          </v-btn>
        </router-link>
      </nav>
      <nav class="options_ham">
        <button>Menu</button>
      </nav>
      
    </v-app-bar> -->

    <v-main>
      <router-view />
    </v-main>
    <Footer />
  </v-app>
</template>

<script>
import Footer from "@/components/Footer.vue";
import Header from "@/components/Header.vue";
import LogoDark from "@/assets/images/logo-marsh.png";

export default {
  components: {
    Footer,
    Header
  },
  data: () => ({
    LogoDark,
  }),
};
</script>
<style >
  .nav{
    display: flex;
    justify-content: space-around;
  }
  .options_ham{
    display: none;
  }
  @media (max-width: 768px) {
    .options_regular{
      display: none;
    }
    .options_ham{
      display:flex;
    }

}
</style>