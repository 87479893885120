<template>
  <div style="background-color: #f9f9f9">
    <div style="background-color: #195BB4; width: 100%; height: 1rem"></div>
    <v-container style="margin-top: 4rem; margin-bottom: 5rem">
      <v-layout row wrap>
        <div
          class="footer-element"
        >
          <v-img
            class="shrink mt-1 hidden-sm-and-down"
            contain
            max-height="100"
            :src="LogoLight"
            max-width="175"
          />
        </div>
        
        <div
          class="footer-element"
        >
          <div style="height: 100%">
            <div style="margin-bottom: 1.5rem">
              <p>Av. Américo Vespucio norte 2500, oficina 30</p>
              <p>Vitacura, Santiago</p>
            </div>

            <div style="margin-bottom: 1.5rem">
              <p>
                Teléfono:
                <a style="color: #41b0e7" href="tel:600 240 4600"
                  >600 240 4600</a
                >
              </p>
            </div>

            <!-- <div style="margin-bottom: 1.5rem">
              <p>
                Email:
                <a
                  style="color: #41b0e7"
                  href="mailto:SeguroMascota@bukerseguros.cl"
                  >SeguroMascota@bukerseguros.cl</a
                >
              </p>
            </div> -->

          </div>
        </div>
        <div class="footer-element" style="min-width: 150px">
          <v-col class="ma-0 pa-0">
            <div style="height: 100%">Síguenos en</div>
            <v-row class="ma-0 pa-0">
              <!-- <v-btn class="ma-0" text icon color="#FFFF" href="https://www.instagram.com/bukersegurosonline/" target="_blank">
                <v-icon>mdi-instagram</v-icon>
              </v-btn>
              <v-btn class="ma-0" text icon color="#FFFF" href="https://www.facebook.com/bukeronline" target="_blank">
                <v-icon>mdi-facebook</v-icon>
              </v-btn> -->
              <v-btn class="ma-0" text icon color="secondary" href="https://www.linkedin.com/company/mesoscorredoradeseguros/?originalSubdomain=cl" target="_blank">
                <v-icon>mdi-linkedin</v-icon>
              </v-btn>


            </v-row>
              <br>
              Este riesgo es tomado por         
              <v-img
            class="shrink mt-1 hidden-sm-and-down"
            contain
            max-height="100"
            :src="LogoSB"
            max-width="175"
          />
          </v-col>
          
        </div>
      </v-layout>
      
    </v-container>
    <footer class="footer">
        <section style="color:white">
           © {{year}} Powered By &thinsp;<a style="color:white" href="https://embedx.io/"> EmbedX</a>
        </section>
    </footer>
  </div>
  
</template>

<script>
import LogoLight from "@/assets/images/logo-mesos.png";
import LogoSB from "@/assets/images/SB_logo.svg";

export default {
  name: "Footer",
  data: () => ({
    LogoLight,
    LogoSB
  }),
};
</script>

<style lang="scss" scoped>
@import url("../styles/main.scss");

.footer-element {
  color: #000;
  min-height: 100px;
  margin: 1rem;
  display: flex;
  align-items: flex-start;

  li {
    margin-bottom: 1rem;
  }

  p {
    line-height: 1;
    margin-bottom: 0.5rem;
  }

  .ln {
    color : #f49e15
  }
}

.layout.wrap {
  justify-content: space-around;
}

.footer{
    section{
        display: flex;
        justify-content: center;
        padding: 1rem 0;
        div {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin: 0.7rem;
            div{
                padding: 0.5rem 0;
                display: flex;
                flex-direction: column;
                a{
                    text-decoration: none;
                    padding-bottom: 1rem;
                }
            }
        }
        .footer-contact{
            img{
                width: 60%;
            }
            div{
                display: flex;
                flex-direction: row;
                a{
                    padding-bottom: 0;
                }
                img{
                    width: 8%;
                    padding-right: 10px;
                }
            }
            .footer-contact-rrss{
                img{
                    width: 20%
                }
            }
        }
        &:last-child{
            background: #195BB4;
            padding: 10px;
            p{
                display: flex;
                margin: auto;
                color: #FFF;
                img{
                    margin: 3px;
                }
            }
        }
    }
}

</style>
