<template>
    <div color="#FFFFFF" eight="75" >
      <section class="nav">
        <router-link :to="{ path: '/' }" class="nav-img footer-image">
            <v-img
                :src="LogoDark"
                max-width="165"
                alt="Logo"
            />
        </router-link>

        <!-- <nav class="options_regular">
            <router-link style="text-decoration: none" :to="{ path: '/', hash: '#seguros' }" @click="navHandler">
                <span class="mr-2">Seguros</span>
            </router-link>

            <router-link style="text-decoration: none" :to="{ path: '/cotizador' }" @click="navHandler">
                <span class="mr-2">Cotizador</span>
            </router-link>

            <router-link style="text-decoration: none" :to="{ path: '/', hash: '#nosotros' }" @click="navHandler">
                <span class="mr-2">Nosotros</span>
            </router-link>

            <router-link style="text-decoration: none" :to="{ path: '/', hash: '#contacto' }" @click="navHandler">
                <span class="mr-2">Contacto</span>
            </router-link>

            <router-link style="text-decoration: none" :to="{ path: '/', hash: '#mis-seguros' }" @click="navHandler">
                <span class="mr-2">Mis seguros</span>
            </router-link>
        </nav> -->

        <!-- <nav :class="navProps.style">
            <button @click="navHandler">{{navProps.text}}</button>
        </nav> -->
      </section>
      <!-- <section v-show="showOptions" class="options_ham">
          <router-link style="text-decoration: none" :to="{ path: '/', hash: '#seguros' }" @click="navHandler">
                <span class="mr-2">Seguros</span>
            </router-link>

            <router-link style="text-decoration: none" :to="{ path: '/cotizador' }" @click="navHandler">
                <span class="mr-2">Cotizador</span>
            </router-link>

            <router-link style="text-decoration: none" :to="{ path: '/', hash: '#nosotros' }" @click="navHandler">
                <span class="mr-2">Nosotros</span>
            </router-link>

            <router-link style="text-decoration: none" :to="{ path: '/', hash: '#contacto' }" @click="navHandler">
                <span class="mr-2">Contacto</span>
            </router-link>

            <router-link style="text-decoration: none" :to="{ path: '/', hash: '#mis-seguros' }" @click="navHandler">
                <span class="mr-2">Mis seguros</span>
            </router-link>
      </section> -->
    </div>

</template>

<script>
import LogoDark from "@/assets/images/logo-mesos.png";

export default {
  data: () => ({
    LogoDark,
    showOptions: false
  }),
  methods: {
      navHandler: function(){
          this.showOptions = !this.showOptions;
      }
  },
  computed: {
      navProps(){
          if (this.showOptions){
              return ({text: "Cerrar", style: "btn-ham open"})
          }else{
              return ({text:"Menu", style: "btn-ham"}) 
          }
      }
  }
};
</script>
<style >
.nav{
    display: flex;
    justify-content: space-between !important;
    align-items: center;
    padding: 0.1rem;  
    background-color: #f4f4f4;
}
.options_regular{ 
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
      
}
span{
    padding: 2px;
}
span:hover{
    background-color: rgba(124, 122, 122, 0.2);
    border-radius: 10%;
      
}
.btn-ham, .options_ham{
    display: none;
}
.nav-img{
    width: 15%;
}
img{
    width: 100%;
}

@media (max-width: 768px) {
.options_regular{
    display: none;
}
.btn-ham{
    display: inline;
    padding: 2px;
    background-color: rgba(124, 122, 122, 0.2);
}
.open{
    color: #f4f4f4;
    background-color: #CC0000;
}
.options_ham{
    display:flex;
    flex-direction: column;
    position: absolute;
    width: 50%;
    z-index: 3;
    background-color: rgb(255, 255, 255);
    right: 0;
    background-color: #f4f4f4;
}
.options_ham a{
    text-align: end;
    padding: 5px 0;
    border-top: 1px solid #fff;
}
.nav-img{
    width: 30%;
}

}

@media (max-width: 425px){
    .nav-img{
        width: 40%
    }
    .nav{
        padding: 1rem 1vw;
    }
}
</style>